import React, { useState } from 'react';
import '../styles/opportunityHubPage.css';

const OpportunityHubFilters = () => {
  const [remoteJobsOnly, setRemoteJobsOnly] = useState(false);

  return (
    <div className='opportunity-hub-filters'>
      <div className='schedule-call-form-group schedule-call-form-space-24' style={{ flex: 1, marginRight: '16px' }}>
        <label htmlFor="specializations">Specializations</label>
        <input type="text" id="specializations" placeholder={"Enter search term"} />
      </div>

      <div className='schedule-call-form-group schedule-call-form-space-24' style={{ flex: 1, marginRight: '16px' }}>
        <label htmlFor="locations">Locations</label>
        <input type="text" id="locations" placeholder={"Enter search term"} />
      </div>

      <div className='schedule-call-form-group schedule-call-form-space-24' style={{ flex: 1, marginRight: '16px' }}>
        <label htmlFor="contractTypes">Contract types</label>
        <input type="text" id="contractTypes" placeholder={"Enter search term"} />
      </div>

      <div style={{ display: 'flex', alignItems: 'center' }}>
      <label className='opportunity-hub-opportunities-checkbox' htmlFor="remoteJobsOnly" style={{ marginRight: '8px' }}>
      <input
          type="checkbox"
          id="remoteJobsOnly"
          checked={remoteJobsOnly}
          onChange={() => setRemoteJobsOnly(!remoteJobsOnly)}
        />
          <span class="checkbox-slide round"></span>
        </label>
        <label>Remote Jobs Only</label>
      </div>
    </div>
  );
};

export default OpportunityHubFilters;
