import axios from "../axiosConfig";

export const postCandidate = async (formData, jobId) => {
  try {
    // POST Candidate Manatal
    const responsePostCandidate = await postCandidateManatal(formData);
    if (
      responsePostCandidate &&
      responsePostCandidate.status >= 200 &&
      responsePostCandidate.status <= 204 &&
      responsePostCandidate.data.id &&
      formData.resume
    ) {
      const responsePostResume = await postCandidateResume(
        responsePostCandidate.data.id,
        formData.resume
      );
      if (
        responsePostResume &&
        responsePostResume.status >= 200 &&
        responsePostResume.status <= 204 &&
        responsePostResume.data.created_at
      ) {
        const responsePostMatche = await postMatchCandidateJob(
          jobId,
          responsePostCandidate.data.id
        );
        if (
          responsePostMatche &&
          responsePostMatche.status >= 200 &&
          responsePostMatche.status <= 204 &&
          responsePostMatche.data.id
        ) {
          return responsePostCandidate;
        } else {
          throw new Error("Candidate and Job match could not be created.");
        }
      } else {
        throw new Error("Invalid resume.");
      }
    }
    throw new Error("Invalid parameters on form submit.");
  } catch (error) {
    console.error("Error fetching jobs:", error);
    throw error;
  }
};

const postCandidateManatal = async (formData) => {
  //Set POST params
  const data = {
    full_name: formData.name,
    email: formData.email,
    phone_number: formData.phone,
  };

  const response = await axios
    .request({
      url: "/candidates/",
      method: "POST",
      data: data,
    })
    .catch(function (error) {
      console.error(error);
    });
  return response;
};

const postCandidateResume = async (candidateId, resume) => {
  // Save the file
  // TO DO

  // Resource Url of the saved file
  const resumeUrl = ''
  //Set POST params
  const data = {
    resume_file: resumeUrl,
  };
  const response = await axios
    .request({
      url: `/candidates/${candidateId}/resume/`,
      method: "POST",
      data: data,
    })
    .catch(function (error) {
      console.error(error);
    });
  return response;
};

const postMatchCandidateJob = async (jobId, candidateId) => {
  //Set POST data
  const data = {
    job: jobId,
    candidate: candidateId,
  };
  const response = await axios
    .request({
      url: `/matches/`,
      method: "POST",
      data: data,
    })
    .catch(function (error) {
      console.error(error);
    });
  return response;
};
