import React, { useState, useEffect } from "react";
import OpportunityHubHorizontalItem from "./OpportunityHubHorizontalItem";
import { getJobs } from "../api";
import PaginationOpportunities from "../components/PaginationOpportunities/PaginationOpportunities";

const OpportunityHubHorizontalContainer = () => {
  const [jobs, setJobs] = useState([]);
  const [loadingJobs, setLoadingJobs] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const pageSize = window.innerWidth > 480 ? 5 : 3;

  useEffect(() => {
    async function fetchJobs() {
      try {
        const jobsData = await getJobs(pageSize, currentPage);
        setJobs(jobsData.data.results);
        setTotalPages(Math.ceil(jobsData.data.count / pageSize));
      } catch (error) {
        console.error("Error fetching jobs:", error);
      } finally {
        setLoadingJobs(false);
      }
    }

    fetchJobs();
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div style={{ width: "100%" }}>
      {loadingJobs ? (
        <div>Loading...</div>
      ) : (
        <div>
          {jobs.map((item, index) => (
            <OpportunityHubHorizontalItem job={item} />
          ))}
          <PaginationOpportunities
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </div>
      )}
    </div>
  );
};

export default OpportunityHubHorizontalContainer;
