import React from 'react';
import IconButtonBlue from "./IconButtonBlue";
import '../styles/opportunityHubHorizontalItem.css';
import 'font-awesome/css/font-awesome.min.css';
import { HiOutlineDocument } from "react-icons/hi2";
import { MdOutlineLocationOn } from "react-icons/md";
import { useNavigate } from "react-router-dom";


const OpportunityHubHorizontalItem = ({ job }) => {
    const navigate = useNavigate();
    const handleClick = () => {
      navigate("/offer", { state: { job } });
      window.scrollTo(0, 0);
    };

    let contractDetails = job && job.contract_details ? job.contract_details : "";
    contractDetails = contractDetails.replace(/_/g, " ").toUpperCase();
    const regime = job.is_remote === true ? "Remote" : (job.is_remote === false ? "On-site" : "")
  

    return (
        <div className="opportunity-hub-horizontal-item-container">
            <div className="opportunity-hub-horizontal-item-header">
                <div className="opportunity-hub-horizontal-item-title">{job.position_name}</div>
            </div>
            <div className="opportunity-hub-horizontal-item-content">
                <div className="opportunity-hub-horizontal-item-label opportunity-hub-horizontal-item-label-status opportunity-hub-horizontal-item-content-derived">{job.status.toUpperCase()}</div>
                <div className='opportunity-hub-horizontal-item-label-regime-container opportunity-hub-horizontal-item-content-derived'>
                <div className="opportunity-hub-horizontal-item-label opportunity-hub-horizontal-item-label-regime">{regime}</div>
                </div>
                <div className="opportunity-hub-horizontal-item-icon opportunity-hub-horizontal-item-content-derived">
                    <HiOutlineDocument />
                    <span>&nbsp;{contractDetails}</span>
                </div>
                <div className="opportunity-hub-horizontal-item-icon opportunity-hub-horizontal-item-content-derived">
                    <MdOutlineLocationOn />
                    <span>&nbsp;{job.address}</span>
                </div>
                <IconButtonBlue text={'APPLY'} iconClass={'fa fa-arrow-right'} onclick={handleClick} />
            </div>
        </div>
    );
};

export default OpportunityHubHorizontalItem;
