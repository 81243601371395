import React from "react";
import 'font-awesome/css/font-awesome.min.css';
import '../styles/iconButton.css';

const IconButton = ({ text, iconClass, onClick, isHideTextButton = false }) => {
    const updateButtonText = () => {
        if (window.innerWidth < 480 && isHideTextButton) { // Adjust the breakpoint as needed
            return '';
        }
        return text;
    };

    window.addEventListener('resize', updateButtonText);

    return (
        <button className="icon-button" onClick={onClick}>
            {updateButtonText()} <i className={iconClass}></i>
        </button>
    )
};

export default IconButton;