import React, { useState, useEffect } from "react";
//import data from "../mock/opportunityHub.json";
import IconButton from "./IconButton";
import "../styles/opportunityHub.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import OpportunityHubCard from "./OpportunityHubCard";
import { getJobs } from "../api";

const OpportunityHub = () => {
  const filters = [
    "Developer",
    "Backend",
    "Quality Assurance",
    "Data",
    "TIBCO",
    "SAP",
  ];
  const [jobs, setJobs] = useState([]);
  const [loadingJobs, setLoadingJobs] = useState(true);

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredJobs, setFilteredJobs] = useState([]);

  const handleFilterButtonClick = (event) => {
    const isSelected = event.target.classList.contains(
      "opportunity-hub-btn-filter-selected"
    );
    if (isSelected === true) {
      event.target.classList.remove("opportunity-hub-btn-filter-selected");
      event.target.classList.add("opportunity-hub-btn-filter-unselected");
    } else {
      event.target.classList.remove("opportunity-hub-btn-filter-unselected");
      event.target.classList.add("opportunity-hub-btn-filter-selected");
    }
  };

  useEffect(() => {
    async function fetchJobs() {
      try {
        // To Do - change pageSize according to whats needed for the home page
        const pageSize = 50
        const jobsData = await getJobs(pageSize);
        setJobs(jobsData.data.results);
        setFilteredJobs(jobsData.data.results);
      } catch (error) {
        console.error("Error fetching jobs:", error);
      } finally {
        setLoadingJobs(false);
      }
    }

    fetchJobs();
  }, []);

  useEffect(() => {
  }, [filteredJobs]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setFilteredJobs(jobs)
  };

  const handleSearchClick = () =>{
    try {
      setLoadingJobs(true);
      if(searchTerm && searchTerm.length > 0){
        let jobsFiltered = jobs.filter((job) =>
        job.position_name.toLowerCase().includes(searchTerm.toLowerCase())
      )
        setFilteredJobs(
          jobsFiltered
          );

    }
    } catch (error) {
      console.error("Error in search jobs:", error);
    } finally {
      setLoadingJobs(false);
    }

  }

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "10px",
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    variableWidth: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1023, // Adjust this breakpoint as needed
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 480, // Adjust this breakpoint as needed
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  };

  return (
    <>
      <div className="opportunity-hub-search-section">
        <input
          type="text"
          placeholder="Ex: Data Science"
          className="opportunity-hub-search-input"
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <IconButton
          text={"SEARCH"}
          iconClass={"fa fa-arrow-right"}
          isHideTextButton
          onClick={handleSearchClick}
        />
      </div>
      {/* here we need to get a distinct list of tags from the opportunity collection */}
      <div className="opportunity-hub-button-filters">
        <p className="opportunity-hub-or-search-for">or search for:</p>
        {filters.map((item, index) => (
          <button
            key={`opportunityHubButton${index}`}
            className="opportunity-hub-btn-filter opportunity-hub-btn-filter-unselected"
            onClick={handleFilterButtonClick}
          >
            {item}
          </button>
        ))}
      </div>
      <div className="opportunity-hub-cards">
        {filteredJobs && !loadingJobs ? (
          <Slider {...settings}>
          {filteredJobs.map((item, index) => {
          console.log("filteredjobs:", filteredJobs)
          return (
              <OpportunityHubCard
                key={`opportunityHubCard${index}`}
                job={item}
                /*title={item.position_name}
                          subTitle={item.subTitle}
                          status={item.status.toUpperCase()}
                          contractType={item.contract_details}
                          /*location={item.address} 
                          /*regime={item.regime}*/
              />
            );
          })}
        </Slider>
        ) : (
          <div>Loading...</div>

        )}
      </div>
    </>
  );
};

export default OpportunityHub;
