import ContentImage from "../components/ContentImage";
import React from "react";
import Talent from "../components/Talent";
import "../styles/nearshore.css";
import DigitalTraces from "../components/DigitalTraces";
import ScheduleCall from "../components/ScheduleCall";
import ClientCarrousel from "../components/ClientCarrousel";
import NearshoreHeader from "../components/Headers/NearshoreHeader";
import ourPersonalityBg from '../components/our-methodology-bg.svg';

const Nearshore = () => {
  return (
    <>
      <NearshoreHeader />
      <div className={"centering"}>
        <div className={"clients-wrapper"}>
          <div className="container" style={{ backgroundColor: "#FAFAFA" }}>
            {/* Clients Section */}
            <div className="section-title">
              <img src={"images/numbers/01.png"} alt={"1"} />
              <h3 className="section-title-text nearshore-section-title-text">
                We work with the latest technologies
              </h3>
            </div>
            <ClientCarrousel />
          </div>
        </div>
        {/*   */}
        {/* Why Nearshoring Section */}
        <div className="container">
          <div className={"nearshore-section02"}>
            <div className="nearshore-section02a">
              <div className="section-title nearshore-section02a-title">
                <img src={"images/numbers/02.png"} alt={"2"} />
                <h3 className="section-title-text nearshore-section-title-text">
                  Why Nearshoring?
                </h3>
              </div>
              <div className="nearshore-section02a-text02 nearshore-why-nearshoring-text">
              <p className="">
                We choose the brave players, with the right knowledge and
                detailed implementation. Pretty insightful, isn’t it?
              </p>
              </div>
            </div>
            <div className="nearshore-section02b">
              <div className={"nearshore-three-block"}>
                <div className={"nearshore-single-block"}>
                  <p className={""}>
                    Less effort regarding resources and career management, but
                    still highly qualified and multitask
                  </p>
                </div>
                <div className={"nearshore-single-block"}>
                  <div>
                    <img src={"images/growing/Location.png"} alt={"Location"} />
                  </div>
                  <p className={""}>Prime Location</p>
                </div>
                <div className={"nearshore-single-block"}>
                  <div>
                    <img src={"images/growing/Culture.png"} alt={"Culture"} />
                  </div>
                  <p className={""}>Culture and Diversity</p>
                </div>
              </div>
              <div className={"nearshore-three-block"}>
                <div className={"nearshore-single-block-large"}>
                  <div>
                    <img src={"images/growing/Players.png"} alt={"Players"} />
                  </div>
                  <p className={""}>Nimber Players</p>
                </div>
                <div className={"nearshore-single-block-large"}>
                  <div>
                    <img src={"images/growing/Legal.png"} alt={"Legal"} />
                  </div>
                  <p className={""}>Legal and Focus</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*   */}
        {/* Why Portugal section */}
        <div>
          <div className="container">
            <ContentImage imgSrc={"images/WhyPT.png"} imagePosition={"left"} additionalImgClass={"nearshore-whypt-img"}>
              <div className="nearshore-section03a">
                <div className="section-title">
                  <img src={"images/numbers/03.png"} alt={"3"} />
                  <h3 className="section-title-text nearshore-section-title-text">
                    Why Portugal?
                  </h3>
                </div>
                <p className="nearshore-section03a-text02 nearshore-why-nearshoring-text">
                  From a technical or creative standpoint as well as an
                  investment standpoint, Portugal is extremely competitive on
                  global markets. Such an opportunity, isn't it?
                </p>
              </div>
            </ContentImage>
            <div className="nearshore-section03b">
              <div className={"nearshore-three-block"}>
                <div className={"nearshore-single-item"}>
                  <div>
                    <img src={"images/growing/Culture.png"} alt={"Culture"} />
                  </div>
                  <p className={"nimber-margin-0 nearshore-single-item-title"}>
                    Qualified Human Resources
                  </p>
                  <p
                    className={
                      "nimber-margin-0 nearshore-single-item-description"
                    }
                  >
                    (in terms of professional, interpersonal, and language
                    skills)
                  </p>
                </div>
                <div className={"nearshore-single-item"}>
                  <div>
                    <img src={"images/growing/Legal.png"} alt={"Legal"} />
                  </div>
                  <p className={"nimber-margin-0 nearshore-single-item-title"}>
                    Quality and growth of infrastructures
                  </p>
                  <p
                    className={
                      "nimber-margin-0 nearshore-single-item-description"
                    }
                  >
                    (in terms of professional, interpersonal, and language
                    skills)
                  </p>
                </div>
                <div className={"nearshore-single-item"}>
                  <div>
                    <img src={"images/growing/Location.png"} alt={"Location"} />
                  </div>
                  <p className={"nimber-margin-0 nearshore-single-item-title"}>
                    Lower labor costs and greater labor flexibility
                  </p>
                  <p
                    className={
                      "nimber-margin-0 nearshore-single-item-description"
                    }
                  >
                    (comparison to other European nations)
                  </p>
                </div>
              </div>
              <div className={"nearshore-three-block"}>
                <div className={"nearshore-single-item"}>
                  <div>
                    <img src={"images/growing/Players.png"} alt={"Players"} />
                  </div>
                  <p className={"nimber-margin-0 nearshore-single-item-title"}>
                    One of the world's most prestigious
                  </p>
                  <p
                    className={
                      "nimber-margin-0 nearshore-single-item-description"
                    }
                  >
                    innovative, sustainable, and entrepreneurial cities is
                    Lisbon.
                  </p>
                </div>
                <div className={"nearshore-single-item"}>
                  <div>
                    <img
                      src={"images/growing/TalentCost.png"}
                      alt={"TalentCost"}
                    />
                  </div>
                  <p className={"nimber-margin-0 nearshore-single-item-title"}>
                    Ability to adapt
                  </p>
                  <p
                    className={
                      "nimber-margin-0 nearshore-single-item-description"
                    }
                  >
                    to various markets and cultures
                  </p>
                </div>
                <div className={"nearshore-single-item"}>
                  <div>
                    <img src={"images/growing/Culture.png"} alt={"Culture"} />
                  </div>
                  <p className={"nimber-margin-0 nearshore-single-item-title"}>
                    Good weather, good quality of life
                  </p>
                  <p
                    className={
                      "nimber-margin-0 nearshore-single-item-description"
                    }
                  >
                    and a safe place to live.
                  </p>
                </div>
              </div>
            </div>
          </div>
          </div>

          {/*   */}
          {/* Talent Section */}
          <div className={'clients-wrapper'} style={{ backgroundColor: 'transparent', paddingBottom: '0px', paddingTop: '0px' }}>
                <div style={{
                    background: `url(${ourPersonalityBg})`,
                    backgroundBlendMode: 'hard-light',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'top center',
                    backgroundSize: 'cover',
                    width: '100%',
                }}>
            <div className={"nearshore-center"}>
              <div className="section-title nearshore-section-title">
                <img src={"images/numbers/04.png"} alt={"4"} />
                <h3 className="section-title-text nearshore-section-title-text">
                  Thriving in Diversity,<br></br>Culture and Talent
                </h3>
              </div>
              <p className="nearshore-thriving-text">Learn more about the perks & benefits we offer.</p>
              <Talent />
            </div>
          </div>
            </div>
          {/*    */}

          {/* Schedule a call section */}
          <ScheduleCall />
          {/*    */}
      </div>
    </>
  );
};

export default Nearshore;
