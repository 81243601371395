import React from 'react';
import KnowHowCard from './KnowHowCard';
import Slider from 'react-slick';
import '../styles/knowHowCardContainer.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


const KnowHowCardContainer = () => {
    const cardData = [
        {
            imageUrl: '/images/numbers/01.png',
            title: 'Software Engineering',
            itemList: ['Architecture', 'Application Development', 'Mobile Development', 'Functional Layer', 'Tests'],
            leftLine: false,
            rightLine: true
        },
        {
            imageUrl: '/images/numbers/02.png',
            title: 'Systems Engineering',
            itemList: ['Cloud', 'Systems Administration Database (Administration and Development)', 'Networking', 'TroubleShooting', 'Middleware'],
            leftLine: true,
            rightLine: true
        },
        {
            imageUrl: '/images/numbers/03.png',
            title: 'Data Engineering',
            itemList: ['Big Data', 'Data Science', 'Business Intelligence', 'Machine Learning'],
            leftLine: true,
            rightLine: false
        },
    ];

    const settings = {
        dots: true, // Show dot indicators
        infinite: false, // Infinite looping
        speed: 500, // Transition speed
        slidesToShow: 3, // Number of slides to show on desktop
        slidesToScroll: 1, // Number of slides to scroll
        responsive: [
            {
                breakpoint: 768, // Width up to which the below settings apply
                settings: {
                    slidesToShow: 1, // Only show one slide on screens smaller than 768px
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    arrows: false,
                }
            }
        ]
    };


    return (
        <div className="know-how-card-container">
            <Slider {...settings}>
                {cardData.map((card, index) => (
                    <KnowHowCard
                        key={index}
                        imageUrl={card.imageUrl}
                        title={card.title}
                        itemList={card.itemList}
                        leftLine={card.leftLine}
                        rightLine={card.rightLine}
                    />
                ))}
            </Slider>
        </div>
    );
};

export default KnowHowCardContainer;
