import axios from 'axios';

// Create an instance of axios with custom configuration
const instance = axios.create({
  baseURL: `https://core.api.manatal.com/open/v3`, 
  headers: {
    accept: 'application/json',
    Authorization: `Token ${process.env.REACT_APP_API_KEY}` 
  }
});

export default instance;
