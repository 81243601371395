import React from 'react';
import './paginationOpportunities.css';

const PaginationOpportunities = ({ currentPage, totalPages, onPageChange }) => {
  const handlePrevious = () => {
    onPageChange(currentPage - 1);
  };

  const handleNext = () => {
    onPageChange(currentPage + 1);
  };

  return (
    <div className="paginationOpportunities">
      <button
        className="page-item"
        onClick={handlePrevious}
        disabled={currentPage === 1}
      >
        {'<'}
      </button>

      <span className="page-dots">
        {currentPage > 2 && <button className="page-item" onClick={() => onPageChange(1)}>1</button>}
        {currentPage > 3 && <span className="page-dots">...</span>}
        {currentPage > 1 && <button className="page-item" onClick={() => onPageChange(currentPage - 1)}>{currentPage - 1}</button>}
        <button className="page-item current">{currentPage}</button>
        {currentPage < totalPages && <button className="page-item" onClick={() => onPageChange(currentPage + 1)}>{currentPage + 1}</button>}
        {currentPage < totalPages - 1 && <span className="page-dots">...</span>}
        {currentPage < totalPages - 2 && <button className="page-item" onClick={() => onPageChange(totalPages)}>{totalPages}</button>}
      </span>

      <button
        className="page-item"
        onClick={handleNext}
        disabled={currentPage === totalPages}
      >
        {'>'}
      </button>
    </div>
  );
};

export default PaginationOpportunities;
