import React from "react";
import "../../styles/header.css";

const OfferHeader = () => {
  const menuItems = [
    {
      title: "Nimberverse",
      path: "/nimberverse",
      selected: false,
    },
    {
      title: "TechEdge",
      path: "/techedge",
      selected: false,
    },
    {
      title: "Nearshore",
      path: "/nearshore",
      selected: false,
    },
    {
      title: "Opportunity Hub",
      path: "/opportunity-hub",
      selected: false,
    },
    // {
    //     title: 'Outer World',
    //     path: '/outer-world',
    //     selected: false
    // }
  ];

  return (
    <div className="header-no-video centering">
      <div
        className="header-menu-row centering"
        style={{ justifyContent: "center" }}
      >
        <div style={{ padding: "20px", backgroundColor: "#000128" }}>
          <div className={"logo-holder"}>
            <a href="/">
              <img className="logo" src="images/Logo-white.png" alt="Logo" />
            </a>
            <input type="checkbox" id="menu-toggle" className="menu-toggle" />
            <label htmlFor="menu-toggle" className="menu-icon">
              &#9776;
            </label>
            <div className="menu">
              {menuItems.map((item, index) => (
                <a
                  key={index}
                  className={`menu-item header-menu-item-text ${
                    item.selected === true ? "header-menu-item-selected" : ""
                  }`}
                  href={`${item.path}`}
                >
                  {item.title}
                  {item.selected === true && (
                    <div className="menu-item-selected-bar">___</div>
                  )}
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfferHeader;
