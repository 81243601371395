import IconButtonBlue from "./IconButtonBlue";
import "font-awesome/css/font-awesome.min.css";
import "../styles/opportunityHubCard.css";
import { useNavigate } from "react-router-dom";

const OpportunityHubCard = ({ job }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/offer", { state: { job } });
    window.scrollTo(0, 0);
  };

  let contractDetails = job && job.contract_details ? job.contract_details : "";
  contractDetails = contractDetails.replace(/_/g, " ").toUpperCase();
  const regime = job.is_remote === true ? "Remote" : (job.is_remote === false ? "On-site" : "")

  return (
    <div className="opportunity-hub-card">
      <h4>{job.position_name}</h4>
      <h4>{job.subTitle}</h4>
      <span>{job.status.toUpperCase()}</span>
      <div className="opportunity-hub-card-regime-container">
        <div className="opportunity-hub-card-regime">{regime}</div>
      </div>
      <div className="opportunity-hub-card-contract">
        <i className="fa fa-file-o"></i>
        <p>{contractDetails}</p>
      </div>
      <div className="opportunity-hub-card-location">
        <i className="fa fa-map-marker"></i>
        <p>{job.address}</p>
      </div>
      <div>
        <IconButtonBlue
          text={"APPLY"}
          iconClass={"fa fa-arrow-right"}
          onclick={handleClick}
        />
      </div>
    </div>
  );
};

export default OpportunityHubCard;
