import React, { useState, useRef } from 'react';
import IconButton from "./IconButton";
import SendUsYourCVFile from './SendUsYourCVFile';
import '../styles/sendUsYourCVForm.css';

const SendUsYourCVForm = () => {
    const [dragging, setDragging] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const fileInputRef = useRef(null);

    const handleDragEnter = (e) => {
        e.preventDefault();
        setDragging(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        setDragging(false);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setDragging(false);

        const files = Array.from(e.dataTransfer.files);
        let fileList = selectedFiles.concat(files);
        setSelectedFiles(fileList);

        console.log(fileList);
    };

    const handleFileInputChange = (e) => {
        const files = Array.from(e.target.files);
        setSelectedFiles(files);
    };

    const uploadFiles = () => {
        console.log(selectedFiles[0]);
        return false;
    };

    const handleBrowseClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleRemoveFile = (fileToRemove) => {
        setSelectedFiles((prevSelectedFiles) =>
            prevSelectedFiles.filter((file) => file !== fileToRemove)
        );
    };


    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <form className="sendUsYourCVForm-container">
                <div>
                    <h4 className="sendUsYourCVForm-title">Nimber is the right place to you!</h4>
                </div>
                <div>
                    <p className="sendUsYourCVForm-description">
                        Send us your CV or professional profile link and we find a relevant project for you.
                    </p>
                </div>
                <div className="sendUsYourCVForm-inputs-container">
                    <div className="sendUsYourCVForm-inputs">
                        <div>
                            <label htmlFor="txtName">Name*</label>
                            <input type="text" id="txtName" placeholder="Enter your name" />
                        </div>

                        <div>
                            <label htmlFor="txtEmail">Email*</label>
                            <input type="email" id="txtEmail" placeholder="Enter your email" />
                        </div>
                    </div>
                </div>
                <div className="sendUsYourCVForm-inputs-container">
                    <div
                        className="sendUsYourCVForm-upload-file-section"
                        onDragEnter={handleDragEnter}
                        onDragLeave={handleDragLeave}
                        onDragOver={handleDragOver}
                        onDrop={handleDrop}>
                        <div className="sendUsYourCVForm-upload-file-section-title-container">
                            <i className="fa fa-upload" aria-hidden="true"></i>
                            <h4>&nbsp; Drag and drop file here or&nbsp;<span className='sendUsYourCVForm-browse-file' onClick={handleBrowseClick}>browse</span>
</h4>
                            <input
                                type='file'
                                accept='.pdf, .txt, .png, .jpeg, .gif'
                                multiple={true}
                                onChange={handleFileInputChange}
                                ref={fileInputRef}
                                style={{ display: 'none' }} />
                            {/* <a href="#">browse</a> */}
                        </div>
                        <div className="sendUsYourCVForm-upload-file-section-title-container">
                            <p className="sendUsYourCVForm-upload-file-section-description">Only pdf, txt, png, jpeg, gif are allowed up to &nbsp;</p>
                            <span className="sendUsYourCVForm-upload-file-section-description-mb">2mb.</span>
                        </div>
                    </div>
                </div>
                <div className="sendUsYourCVForm-attached-file-container">
                    {selectedFiles.map((file, index) => (<SendUsYourCVFile key={file.name} file={file} removeCallback={() => handleRemoveFile(file)} />))}
                </div>
                <div className="sendUsYourCVForm-button-container">
                    <IconButton iconClass={'fa fa-arrow-right'} text={'SEND'} onClick={uploadFiles} />
                </div>
            </form>
            <img src="images/lets-talk.png" width={'150px'} height={'150px'} style={{ position: 'relative', top: '-95px', left: '-100px' }} />
        </div>
    );
};

export default SendUsYourCVForm;