import axios from '../axiosConfig';

export const getJobs = async (pageSize, page) => {
  try {      
    const params = {
        status: 'active'
      };
    
    if(pageSize) {
        params.page_size = pageSize;
    }

    if(page) {
        params.page = page;
    }
    
    const response = await axios
        .request({
            url: '/jobs/',
            method: 'GET',
            params: params
          })
        .catch(function (error) {
          console.error(error);
        });

        return response;
  } catch (error) {
    console.error('Error fetching jobs:', error);
    throw error;
  }
};