import React, { useState } from "react";

import BackButton from "../components/BackButton";
import OfferHeader from "../components/Headers/OfferHeader";
import JobApplicationForm from "../components/JobApplicationForm";
import JobListing from "../components/JobListing";
import "../styles/offer.css";
import { useLocation } from "react-router-dom";

const OfferPage = () => {
  const { state } = useLocation();
  const job = state ? state.job : null;
  const salaryMin = job && job.salary_min ? job.salary_min : "";
  const salaryMax = job && job.salary_max ? job.salary_max : "";
  const currency = job && job.currency ? job.currency : "";
  const salaryRange =
    currency +
    " " +
    salaryMin +
    (salaryMin && salaryMax ? "-" : "") +
    salaryMax;
  const [applicationSubmitted, setApplicationSubmitted] = useState(false);

  return (
    <>
      <OfferHeader />
      {state && job && !applicationSubmitted ? (
        <div id="offer-page-container" className="offer-page-container">
          <div className="container job-listing-container">
            <div className="offer-page-back-btn">
              <BackButton text="BACK TO OPPORTUNITY HUB" />
            </div>
            <JobListing
              title={job.position_name}
              desription={job.description}
            />
          </div>
          <div className="container job-form-container">
            <div className="job-listing-upload-content">
              <JobApplicationForm
                jobId={job.id}
                status={job.status.toUpperCase()}
                salaryRange={salaryRange}
                setAppSubmitted={setApplicationSubmitted}
              />
            </div>
          </div>
        </div>
      ) : applicationSubmitted ? (
        <div className="container">
          <BackButton text="BACK TO OPPORTUNITY HUB" />
          <h2 className="offer-select-opportunity">
            Application submitted successfully.
          </h2>
        </div>
      ) : (
        <div className="container">
          <BackButton text="BACK TO OPPORTUNITY HUB" />
          <h2 className="offer-select-opportunity">
            Please select an opportunity to apply.
          </h2>
        </div>
      )}
    </>
  );
};

export default OfferPage;
