import { useState } from "react";
import "../styles/button.css";
import "../styles/form.css";
import "../styles/scheduleCall.css";

const ScheduleCall = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    companyName: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    //console.log(formData);  You can access form data here
  };

  return (
    <div className={"schedule-wrapper clients-wrapper"}>
      <div className="container" style={{ width: "100%", padding: "0px" }}>
        <div className="schedule-call-container">
          <div className="schedule-call-image">
            <h3>
              Schedule a call
              <br />
              with us
            </h3>
            <div className="schedule-call-title-image">
              <img src="/images/TempCEO.png" />
              <div className="schedule-call-details-container">
                <h4 className="schedule-call-name">Name</h4>
                <span className="schedule-call-title">CEO</span>
              </div>
            </div>
          </div>
          <div className={"schedule-call-form"} style={{backgroundColor:'#FAFAFA'}}>
            <form onSubmit={handleSubmit}>
              <h3 className="schedule-call-form-space-24">
                Let's work together
              </h3>
              <div className="schedule-call-form-group schedule-call-form-space-24">
                <label htmlFor="name">Name*</label>
                <input
                  className={"form-group-input"}
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  placeholder={"Enter your name"}
                  required
                />
              </div>
              <div className="schedule-call-form-group schedule-call-form-space-24">
                <label htmlFor="email">Email*</label>
                <input
                  className={"form-group-input"}
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  placeholder={"Enter your email"}
                  required
                />
              </div>
              <div className="schedule-call-form-group schedule-call-form-space-24">
                <label htmlFor="phoneNumber">Phone Number*</label>
                <input
                  className={"form-group-input"}
                  type="tel"
                  id="phoneNumber"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleInputChange}
                  placeholder={"(750) 932-2345"}
                  required
                />
              </div>
              <div className="schedule-call-form-group schedule-call-form-space-24">
                <label htmlFor="companyName">Company Name*</label>
                <input
                  className={"form-group-input"}
                  type="text"
                  id="companyName"
                  name="companyName"
                  value={formData.companyName}
                  onChange={handleInputChange}
                  placeholder={"Enter your Company Name"}
                  required
                />
              </div>
              <div className="schedule-call-form-group schedule-call-form-space-24">
                    <button type="submit" className="nimber-button">
                      SET UP A MEETING
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 512 512"
                        style={{ fill: "white" }}
                      >
                        <path d="M16.1 260.2c-22.6 12.9-20.5 47.3 3.6 57.3L160 376V479.3c0 18.1 14.6 32.7 32.7 32.7c9.7 0 18.9-4.3 25.1-11.8l62-74.3 123.9 51.6c18.9 7.9 40.8-4.5 43.9-24.7l64-416c1.9-12.1-3.4-24.3-13.5-31.2s-23.3-7.5-34-1.4l-448 256zm52.1 25.5L409.7 90.6 190.1 336l1.2 1L68.2 285.7zM403.3 425.4L236.7 355.9 450.8 116.6 403.3 425.4z" />
                      </svg>
                    </button>
                  </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScheduleCall;
