// JobApplicationForm.jsx
import React, { useState } from "react";
import PropTypes from "prop-types";
import "../styles/jobApplicationForm.css";
import { postCandidate } from "../api";

const JobApplicationForm = ({
  jobId,
  status,
  salaryRange,
  setAppSubmitted,
}) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    resume: null,
  });

  const statusColors = {
    active: "#00ff00",
    "on hold": "yellow",
    completed: "red",
    cancelled: "red",
  };

  const maxFileSize = 2 * 1024 * 1024;

  const statusColor = statusColors[status.toLowerCase()] || "black";

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size <= maxFileSize) {
      setFormData({ ...formData, resume: file });
    } else {
      alert("Max. 2mb allowed.");
    }
  };

  const handleRemoveFile = () => {
    setFormData({ ...formData, resume: null });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Perform the submission logic here, such as sending to an API
    try {
      if (
        formData &&
        formData.name &&
        formData.email &&
        formData.phone &&
        formData.resume
      ) {
        const response = await postCandidate(formData, jobId);

        if (response && response.status >= 200 && response.status <= 204) {
          setAppSubmitted(true);
        } else {
          alert("There was an error in your application, please try again.");
        }
      }else{
        alert("There was an error in your application, please try again.");
      }
    } catch (error) {
      console.error("Error in application submittion", error);
    } finally {
    }
  };

  return (
    <form className="job-application-form" onSubmit={handleSubmit}>
      <div className="status" style={{ color: statusColor }}>
        {status}
      </div>
      <div className="salary-range">{salaryRange}</div>
      <div className="freelance">Before Tax</div>

      <label htmlFor="name">What is your name?*</label>
      <input
        type="text"
        id="name"
        name="name"
        placeholder="Enter your name"
        required
        onChange={handleInputChange}
      />

      <label htmlFor="email">What is your email address?*</label>
      <input
        type="email"
        id="email"
        name="email"
        placeholder="Enter your email"
        required
        onChange={handleInputChange}
      />

      <label htmlFor="phone">What is your phone number?*</label>
      <input
        type="tel"
        id="phone"
        name="phone"
        placeholder="Enter your number"
        required
        onChange={handleInputChange}
      />

      <label className="file-upload-label">
        Drag and drop file here or
        <span className="browse"> browse</span>
        <input
          type="file"
          id="resume"
          name="resume"
          accept=".pdf,.txt,.png,.jpg,.gif"
          required
          onChange={handleFileChange}
        />
        <span className="file-upload-label-placeholder">
          <br />
          Only pdf, txt, png, jpeg, gif are allowed up to 2mb.
        </span>
      </label>
      {formData.resume && (
        <div className="file-uploaded-container">
          <p className="file-uploaded">Selected file: {formData.resume.name}</p>
          <button
            className="remove-file-btn"
            type="button"
            onClick={handleRemoveFile}
          >
            X
          </button>
        </div>
      )}
      <button type="submit" className="apply-button">
        APPLY NOW
      </button>
    </form>
  );
};

JobApplicationForm.propTypes = {
  status: PropTypes.string.isRequired,
  salaryRange: PropTypes.string.isRequired,
};

export default JobApplicationForm;
